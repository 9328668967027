import { Injectable } from '@angular/core';
import { CurrentUserPreferenceStore } from '../../core/store/current-user-preference.store';
import {
  StatusDetailsLatestDTO,
  StatusHistoryDTO,
} from '../dto/status-update.dto';
import { UserConversion } from '../../features/organization/users/conversion/user.conversion';
import { TableConversion } from './table.conversion';
import { StatusDetailsDTO } from '../dto/status.dto';
import { convertResponseToUserMinimalDTO } from '../../features/organization/users/dto/user.dto';
import { FileConversion } from './file.converstion.dto';

@Injectable({
  providedIn: 'root',
})
export class StatusUpdateConversion {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private userConv: UserConversion,
    private tableConv: TableConversion,
    private fileConv: FileConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }

  resToStatusDetailsDTO(response: any): StatusDetailsDTO {
    return {
      id: response.id,
      title: response[`title_${this.lang}`],
      type: response.type,
      color_code: response.color_code,
      count: response.count,
    };
  }

  resToLatestStatusDetailsDTO(
    response: any,
    statusPrefix = ''
  ): StatusDetailsLatestDTO | null {
    if (!response) {
      return null;
    }

    return {
      id: response.id,
      status: this.tableConv.resToColordItem(response, statusPrefix),
      comment: response.comment,
      updated_at: response.created_at,
      updated_by: convertResponseToUserMinimalDTO(
        response,
        this.lang,
        'created_user_'
      ),
      percentage: response.percentage,
    };
  }
  resToStatusHistoryDTO(response: any, statusTitle:string): StatusHistoryDTO | null {
    if (!response) {
      return null;
    }

    return {
      id: response.id,
      updated_by: this.userConv.resToUserMinimalDTO(response, 'created_user'),
      updated_at: response.created_at,
      comment: response.comment,
      documents: this.fileConv.resToFileDetailsDTOArray(response.documents),
      status: this.tableConv.resToColordItem(response, statusTitle),
    };
  }
}
